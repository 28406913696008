<template>
    <div>
        <CustomTable
			ref="table"
            id_table="liste_courrier_type_tiers"
            :busy.sync="table_busy"
            primaryKey="model_id"
            :items="courrier_type"
        />
    </div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
    import ModelMixin from "@/mixins/Model.js";
    import Navigation from "@/mixins/Navigation.js";
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTiers from '@/mixins/shutters-manager/TiersCourrier.js'
	import Tiers from "@/mixins/Tiers.js";

	export default {
		name: 'CourrierType',
		mixins: [ModelMixin, Navigation, Shutter, ShutterTiers, Tiers],
		props: ['tiers_id'],
		data () {
			return {
                table_busy: false,
                courrier_type: [],
                events_tab: {
                    'TableAction::goToAddCourrier': this.openAjout,
                    'TableAction::goToEditCourrier': (model) => {
		            	this.editModel(model.model[0].model_id, model.model[0].type.modeltype_template) 
		            },
		            'TableAction::goToPdfCourrier': (model) => {
		            	this.goToPrintCourrier(model.model[0].model_id, model.model[0].model_label)
		            },
		            'TableAction::goToDeleteCourrier': (models_ids) => {
		            	this.goToDeleteCourrier(models_ids)
		            },
					'TableAction::goToSendCourrierTiers': (models_ids) => {
		            	this.sendTiersMail(models_ids)
						.then(() => {
							EventBus.$emit("TableAction::stopSpin")
						})
		            }
                },
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
                this.table_busy = true
                this.courrier_type = await this.loadAllModel(this.tiers_id, ['tiers_envoie_generated', 'tiers_entete_envoie_generated', 'tiers_notification_generated'])
                this.table_busy = false
			},

			openAjout() {
				this.setupCourrierTypeAjout(this.tiers_id)
			},

			editModel(model_id, modeltype_template) {
				this.setupCourrierTypeEdit(this.tiers_id, model_id, modeltype_template)
			},

			async goToPrintCourrier(model_id, model_name) {
				let result = await this.printCourrier(model_id, model_name)
                if(result == null){
                	this.failureToast('toast.print_error')					
                }
                EventBus.$emit("TableAction::stopSpin")
			},

			async goToDeleteCourrier(models_ids) {
				await this.deleteModel(models_ids);
				EventBus.$emit('TableAction::stopSpin')
				this.$refs.table.unselectAll()
				this.init_component()
			}
		},
		
		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			Edit : () => import('@/components/Model/Edit')
		}
	}

</script>