export default {
    methods: {
        getActsShutters(props) {
            return {
                'courrier-type': {
                    name: 'courrier-type',
                    title: this.$t('courrier_type.title'),
                    cmpPath: 'components/Courrier/Tiers',
                    props
                },
                'courrier-type-ajout': {
                    name: 'courrier-type-ajout',
                    title: this.$t('courrier_type.ajout_title'),
                    cmpPath: 'components/Courrier/TiersEdit',
                    props
                },
                'courrier-type-edit': {
                    name: 'courrier-type-edit',
                    title: this.$t('courrier_type.edit_title'),
                    cmpPath: 'components/Courrier/TiersEdit',
                    props
                }
            }
        },

        setupCourrierType(tiers_id) {
            if(this.shutterPanel().isOpen('courrier-type')) {
                this.shutterPanel().close('courrier-type')
                this.shutterPanel().close('courrier-type-ajout')
                this.shutterPanel().close('courrier-type-edit')
                return false
            }

            tiers_id = this.$sync.replaceWithReplicated('tiers', tiers_id)

            const shutters = this.getActsShutters({
                tiers_id
            })

            this.shutterPanel().open(shutters['courrier-type'])
        },

        setupCourrierTypeAjout(tiers_id) {
            if(this.shutterPanel().isOpen('courrier-type-ajout')) {
                this.shutterPanel().close('courrier-type-ajout')
                this.shutterPanel().close('courrier-type-edit')
                return false
            }

            tiers_id = this.$sync.replaceWithReplicated('tiers', tiers_id)

            const shutters = this.getActsShutters({
                tiers_id
            })

            this.shutterPanel().open(shutters['courrier-type-ajout'])
        },

        setupCourrierTypeEdit(tiers_id, model_id, modeltype_template) {
            if(this.shutterPanel().isOpen('courrier-type-edit')) {
                this.shutterPanel().close('courrier-type-edit')
                this.shutterPanel().close('courrier-type-ajout')
                return false
            }

            tiers_id = this.$sync.replaceWithReplicated('tiers', tiers_id)

            const shutters = this.getActsShutters({
                tiers_id,
                model_id,
				modeltype_template
            })

            this.shutterPanel().open(shutters['courrier-type-edit'])
        }
    }
}